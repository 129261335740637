<template>
  <div class="speech-control">
    <div class="speech-control-nav">语音主控</div>
    <div class="speech-socket-connect">
      <span v-if="socketConnected" style="color: #22fc22">已连接</span>
      <span v-else style="color: #cccccc">连接断开</span>
    </div>
    <div class="speech-client-id" clickable @click.stop="showNumberKeyboard = true">
      {{ clientId }}
      <span v-if="!clientId" class="speech-client-id-placeholder">请输入8位终端编号</span>
    </div>
    <van-number-keyboard
      v-model="clientId"
      :show="showNumberKeyboard"
      :maxlength="8"
      @blur="showNumberKeyboard = false"
    />
    <div class="speech-messages">
      <div class="speech-message" v-for="(message, index) in messages" :key="index"> {{ message['clientId'] }} : {{ message['message'] }}</div>
    </div>
  </div>
</template>
<script>
import { io } from 'socket.io-client'
import { mapState } from 'vuex'

export default {
  name: 'SpeechControl',
  components: {},
  data () {
    return {
      showNumberKeyboard: false,
      socket: null,
      socketConnected: false,
      clientId: '',
      messages: []
    }
  },
  computed: {
    ...mapState({
      speechModalDisplay: state => state.speech.speechModalDisplay
    })
  },
  methods: {},
  mounted () {
    this.socket = io('https://xbi.hydts.cn', {
      path: '/shs/socket.io',
      autoConnect: true,
      reconnectionAttempts: 10
    })
    this.socket.on('speechMessage', (args) => {
      console.log('speechMessage', args)
      const { clientId, message } = args
      this.messages.push({ clientId, message })
    })
    this.socket.on('connect', () => {
      this.socketConnected = true
    })
    this.socket.on('disconnect', () => {
      this.socketConnected = false
    })
    this.$eventBus.$on('SPEECH_CONTROL_KEYWORD', (keyword) => {
      if (this.clientId) {
        this.socket.emit('speechMessage', {
          clientId: this.clientId,
          message: keyword
        })
      }
    })
  },
  destroyed () {
    this.$eventBus.$off('SPEECH_CONTROL_KEYWORD')
    this.$store.dispatch('speech/setSpeechControl', false)
    if (this.socket) this.socket.disconnect()
  }
}
</script>
<style lang="less" scoped>
.speech-control {
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  height: 100vh;
  .speech-control-nav {
    color: rgba(255,255,255,0.6);
    text-align: center;
    font-size: 1.5em;
    padding-top: 1.5em;
  }
  .speech-socket-connect {
    text-align: center;
    font-size: 0.75em;
    color: #fff;
  }
  .speech-client-id {
    color: #fff;
    text-align: center;
    font-size: 2em;
    border-bottom: 1px solid rgba(255,255,255,0.6);
    margin: 1em 3em;
  }
  .speech-client-id-placeholder {
    font-size: 0.5em;
  }
  .speech-messages {
    .speech-message {
      text-align: center;
      font-size: 0.85em;
      line-height: 1.5em;
    }
  }
}
</style>
